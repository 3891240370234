canvas {
  position: absolute;
  top: 0;
  left: 0;
}

#main-title {
  color: white;
  font-size: 72px;
  width: 500px;
  font-weight: bold;
  margin: 0;
}

#sub-title {
  color: #22ffff;
}

@media only screen and (max-width: 600px) {
  #main-title {
    font-size: 42px;
  }

  #sub-title {
    font-size: 14px;
  }
}

.title-container {
  opacity: 1;
  padding-left: 5%;
  animation-name: fadeIn;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
  text-align: left;
}

.icon {
  width: 50px;
  height: 50px;
}

.icon:hover {
  cursor: pointer;
}
@keyframes fadeIn {
  0% {
    padding-top: 100px;
    opacity: 0;
  }
  100% {
    opacity: 1;
    padding-top: 0px;
  }
}
.banner-inner-container {
  padding-left: 32px;
  padding-right: 32px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;
}

.pancake-button {
  width: 60px;
  height: 40px;
}
