.option {
  display: flex;
  background-color: #dedede;
  padding: 10px;
  color: #222;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
}

.selected-option {
  background-color: #22ffff;
  cursor: default;
}

.options-container {
  display: flex;
  flex-direction: row;
}

.optionText {
  text-align: center;
  margin: 0 auto;
}

.icon-container {
  width: 160px;
  height: 160px;
  border-radius: 80px;
  background-color: #22ffff;
  -webkit-box-shadow: 3px 3px 8px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 3px 3px 8px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.token-title {
  text-align: left;
  color: #dedede;
  font-size: 28px;
  min-width: 300px;
}

.token-text {
  text-align: left;
  color: #dedede;
  font-size: 16px;
}

#token-address {
  font-size: 24px;
  font-weight: 400;
  color: #22ffff;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  #token-address {
    font-size: 14px;
  }
}
